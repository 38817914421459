import React from "react";
import Project from "../templates/project";

export const UnpublishedPage = props => {
  if (typeof window !== "undefined") {
    const previewData = window.__PRISMIC_PREVIEW_DATA;

    console.log("previewData: ", previewData);

    if (previewData.hasOwnProperty("prismicProject")) {
      return <Project data={previewData.prismicProject} />;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default UnpublishedPage;
